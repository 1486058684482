import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { faEdit, faPlus, faRepeat } from '@fortawesome/pro-regular-svg-icons';
import history from '../../../history';
import settings from '../../../../settings';
import Loader from '../../../components/Loader';
import Searchbar from '../../../components/Searchbar';
import SearchDropdown from '../../../components/SearchDropdown';
import Button from '../../../components/Button';
import DataContainer from '../../../logic/dataContainer';
import getConstants from '../../../logic/constants';
import swal from '@sweetalert/with-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import UserContext from '../../../hooks/UserContext';
import api from '../../../logic/api';
import HeaderbarButton from '../../../components/HeaderbarButton';
import Switch from '../../../components/Inputs/Switch';

class ClientsOverview extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);

    this.state = {
      loading: true,
      loadingAppointments: false,
      data: [],
      filteredData: [],
      appointments: [],
      query: params.get('query')?.toLowerCase() || '',
      caseStatusGuid: params.get('status')?.toLowerCase() || '',
      placeOfStorageQueryGuid: params.get('place')?.toLowerCase() || '',
      CarePsych: [],
      CaseStatus: [],
      PlaceOfStorage: [],
      includeClosedCases: false,
    };
  }

  async componentDidMount() {
    await this.initConstants();
    this.loadClients();
  }

  static contextType = UserContext;

  async initConstants() {
    this.setState({
      CarePsych: await getConstants('CarePsych'),
      CaseStatus: await getConstants('CaseStatus'),
      PlaceOfStorage: await getConstants('PlaceOfStorage'),
    });
  }

  loadClients = (includeClosedCases = false) => {
    this.setState({
      loading: true,
      data: [],
      filteredData: [],
    });

    const dataColumns = {
      Guid: '',
      LastName: '',
      FirstName: '',
      AddrCity: '',
      UpdateDate: '',
      UpdateUserName: '',
      IsDone: '',
      Question: '',
      CreateDate: '',
      CaseStatus: '',
      Answer: '',
      PlaceOfStorage: '',
    };

    api(
      'get',
      settings.clientmanagement.client.getFilteredClientList,
      {
        columns: JSON.stringify(dataColumns),
        includeClosedCases,
      },
      false,
    )
      .then((res) => {
        const filteredClientData = new DataContainer(res).getMany(
          'FilteredClient',
        );
        if (filteredClientData.length > 0) {
          const filteredData = filteredClientData.map((data) => {
            const { CaseStatus } = this.state;
            const currCaseStatus = CaseStatus.find((status) => {
              return data.CaseStatus === status.label;
            });

            return {
              ...data,
              CaseStatus: currCaseStatus,
            };
          });

          this.setState({
            data: filteredClientData,
            filteredData: filteredClientData,
            loading: false,
          });
          this.loadAppointments();
          this.applyFilters();
        }
      })
      .catch(() => {
        this.setState({
          data: [],
          filteredData: [],
          loading: false,
        });
      });
  };

  loadAppointments() {
    this.setState({
      loadingAppointments: true,
    });
    const dataColumns = {
      Typ: 'Appointment',
      ClientGuid: this.clientGuid,
    };
    api('get', settings.clientmanagement.dataContainer.getList, {
      parameter: JSON.stringify(dataColumns),
    })
      .then((res) => {
        const appointmentsDC = new DataContainer(res);
        this.setState({
          appointments: appointmentsDC.getMany('Entry'),
          loadingAppointments: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingAppointments: false,
        });
      });
  }

  setPath = (query, status, place) => {
    const baseURI = this.props.match.path.endsWith('search')
      ? this.props.match.path.substring(0, this.props.match.path.length - 7)
      : this.props.match.path.substring(0, this.props.match.path.length - 1);

    // Get the current search parameters from the URL
    const currentSearchParams = new URLSearchParams();
    if (query.length > 0) currentSearchParams.set('query', query);
    if (status.length > 0) currentSearchParams.set('status', status);
    if (place.length > 0) currentSearchParams.set('place', place);

    const newPath = `${baseURI}/search?${currentSearchParams.toString()}`;

    history.replace(newPath);
  };

  _search = (value) => {
    const query = value.toLowerCase();

    this.setState({ query: query }, () => {
      this.setPath(
        query,
        this.state.caseStatusGuid,
        this.state.placeOfStorageQueryGuid,
      );
      this.applyFilters();
    });
  };

  // Filter rows
  filter = (key, value) => {
    const newValue = !!value ? value.toLowerCase() : '';

    this.setState({ [key]: newValue }, () => {
      if (key === 'caseStatusGuid') {
        this.setPath(
          this.state.query,
          newValue,
          this.state.placeOfStorageQueryGuid,
        );
      }
      if (key === 'placeOfStorageQueryGuid') {
        this.setPath(this.state.query, this.state.caseStatusGuid, newValue);
      }
      this.applyFilters();
    });
  };

  applyFilters = () => {
    const { query, caseStatusGuid, placeOfStorageQueryGuid, data } = this.state;

    const filteredData = data.filter((rowData) => {
      const searchableFields = [
        rowData.LastName,
        rowData.FirstName,
        rowData.AddrCity,
        rowData.UpdateUserName,
        rowData.LastContact,
        rowData.CreateDate,
        rowData.CaseStatusText,
        rowData.PlaceOfStorageText,
      ].map((e) => (e ? e.toLowerCase() : e));
      const queryMatch = searchableFields.some((v) =>
        v ? v.indexOf(query) >= 0 : false,
      );

      if (caseStatusGuid && placeOfStorageQueryGuid && query) {
        return (
          queryMatch &&
          rowData.CaseStatus === caseStatusGuid &&
          rowData.PlaceOfStorage === placeOfStorageQueryGuid
        );
      } else if (caseStatusGuid && placeOfStorageQueryGuid) {
        return (
          rowData.CaseStatus === caseStatusGuid &&
          rowData.PlaceOfStorage === placeOfStorageQueryGuid
        );
      } else if (caseStatusGuid) {
        return rowData.CaseStatus === caseStatusGuid;
      } else if (placeOfStorageQueryGuid) {
        return rowData.PlaceOfStorage === placeOfStorageQueryGuid;
      } else if (query) {
        return queryMatch;
      }

      return rowData;
    });

    this.setState({ filteredData });
  };

  //! Don't remove this function now, maybe we need it later
  _openClient = (client) => {
    swal({
      title: 'Sicherheitsabfrage',
      text: `Bitte wählen...`,
      buttons: {
        back: 'abbrechen',
        without: {
          text: 'ohne Sicherheitsfrage öffnen',
        },
        correct: 'Telefonische Beratung, Antwort prüfen',
      },
    }).then((value) => {
      switch (value) {
        case 'without':
          return this.props.history.push(
            `/clientmanagement/detail/${client.Guid}`,
          );
        case 'correct':
          swal({
            title: `${client.Question}`,
            text: `Antwort: ${client.Answer}`,
            buttons: {
              back: 'Antwort falsch',
              correct: 'Antwort richtig',
            },
          }).then((value) => {
            switch (value) {
              case 'correct':
                swal(
                  'Telefonische Beratung. Zugriff wird in Historie gespeichert.',
                  { icon: 'success' },
                );
                return this.props.history.push(
                  `/clientmanagement/detail/${client.Guid}/log`,
                );
              default:
                break;
            }
          });
          break;
        default:
          break;
      }
    });
  };

  _deleteClient = (client) => {
    swal({
      title: 'Löschen?',
      buttons: {
        cancle: 'abbrechen',
        delete: 'löschen',
      },
    }).then((value) => {
      if (value !== 'delete') {
        return;
      }
      api('POST', settings.clientmanagement.dataContainer.remove, {
        guid: client.Guid,
        action: 'Klient/in gelöscht',
        clientGuid: client.Guid,
      })
        .then(() => {
          swal({
            title: 'Gelöscht!',
            text: 'Erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.goBack();
        })
        .catch(() => {
          swal({
            title: 'Löschen nicht möglich!',
            text: 'Es ist ein Fehler beim löschen aufgetreten',
            icon: 'error',
          });
        });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader text="Klienten werden geladen..." />;
    }

    const { filteredData } = this.state;

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <b>Klientenverwaltung</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faRepeat} onClick={this.loadClients}>
              Liste aktualisieren
            </HeaderbarButton>
          </div>
        </div>
        <div className="Page-Content">
          <div className="Buttonbar">
            <Button
              icon={faPlus}
              onClick={() => {
                this.props.history.push(`/clientmanagement/new`);
              }}
              replace
            >
              Neuer Klient/in
            </Button>
          </div>
          <div className="Client-Toolbar">
            <Searchbar
              placeholder="Volltextsuche"
              defaultValue={this.state.query}
              onChange={this._search}
            />
            <div className="Client-Toolbar-Filter-Area">
              <SearchDropdown
                label="Fallstatus:"
                options={this.state.CaseStatus}
                defaultValue={this.state.caseStatusGuid}
                onChange={(value) => {
                  this.filter('caseStatusGuid', value);
                }}
              />
              <SearchDropdown
                label="Aufbewahrungsort der Falldokumentation:"
                options={this.state.PlaceOfStorage}
                defaultValue={this.state.placeOfStorageQueryGuid}
                onChange={(value) => {
                  this.filter('placeOfStorageQueryGuid', value);
                }}
              />
              <Switch
                label="Abgeschlossene Fälle auch anzeigen"
                onChange={(value) => {
                  this.loadClients(value);
                  this.setState({ includeClosedCases: value });
                }}
                labelActive="Ja"
                labelInactive="Nein"
                defaultChecked={this.state.includeClosedCases}
              />
            </div>
          </div>
          <ReactTable
            getTrProps={(_, val) => {
              return {
                onClick: (ev) => {
                  if (ev.target.className === 'delete') {
                    return this._deleteClient(val?.original);
                  }

                  return this._openClient(val?.original);
                },
              };
            }}
            defaultSorted={[
              {
                id: 'LastName',
                desc: false,
              },
            ]}
            pageSize={filteredData.length}
            data={filteredData}
            noDataText="Es wurden keine Klienten gefunden."
            columns={[
              {
                accessor: 'LastName',
                Header: 'Name',
                Cell: ({ original }) => {
                  return <>{original.LastName}</>;
                },
              },
              {
                accessor: 'FirstName',
                Header: 'Vorname',
                Cell: ({ original }) => {
                  return <>{original.FirstName}</>;
                },
              },
              {
                accessor: 'AddrCity',
                Header: 'Wohnort',
                Cell: ({ original }) => {
                  return <>{original.AddrCity}</>;
                },
              },
              {
                accessor: 'CarePsych',
                Header: 'Beraten durch',
                Cell: ({ original }) => {
                  return <>{this.getCarePsychByAppointment(original.Guid)}</>;
                },
              },
              {
                accessor: 'CreateDate',
                Header: 'Angelegt am',
                sortMethod: (a, b) => {
                  return moment(a, 'DD.MM.YYYY hh:mm:ss').isBefore(
                    moment(b, 'DD.MM.YYYY hh:mm:ss'),
                  )
                    ? -1
                    : 1;
                },
                Cell: ({ original }) => {
                  return (
                    <>
                      {moment(
                        original.CreateDate,
                        'DD.MM.YYYY hh:mm:ss',
                      ).format('DD.MM.YYYY')}
                    </>
                  );
                },
              },
              {
                accessor: 'UpdateDate',
                Header: 'Letzter Kontakt',
                sortMethod: (a, b) => {
                  return moment(a, 'DD.MM.YYYY hh:mm:ss').isBefore(
                    moment(b, 'DD.MM.YYYY hh:mm:ss'),
                  )
                    ? -1
                    : 1;
                },
                Cell: ({ original }) => {
                  return (
                    <>
                      {moment(
                        original.UpdateDate,
                        'DD.MM.YYYY hh:mm:ss',
                      ).format('DD.MM.YYYY')}
                    </>
                  );
                },
              },
              {
                accessor: 'CaseStatus',
                Header: 'Fallstatus',
                Cell: ({ original }) => {
                  return (
                    <>
                      {original.CaseStatusText ? original.CaseStatusText : ''}
                    </>
                  );
                },
              },
              {
                accessor: 'PlaceOfStorage',
                Header: 'Falldokumentation',
                Cell: ({ original }) => {
                  return (
                    <>
                      {original.PlaceOfStorageText
                        ? original.PlaceOfStorageText
                        : ''}
                    </>
                  );
                },
              },
              {
                accessor: 'Link',
                Header: 'Zur Detailansicht',
                Cell: () => {
                  return (
                    <>
                      <u>Hier klicken</u>&nbsp;
                      <FontAwesomeIcon icon={faEdit} />
                    </>
                  );
                },
              },
            ]}
          />
        </div>
      </>
    );
  }

  getCarePsychByAppointment(Guid) {
    let carePsychName = '';
    const appointment = this.state.appointments.find((a) => {
      return a.ClientGuid === Guid;
    });
    if (appointment) {
      carePsychName = this.state.CarePsych.find((elem) => {
        return elem.value === appointment.CarePsych;
      })?.label;
    }
    return carePsychName;
  }
}

export default withRouter(ClientsOverview);
